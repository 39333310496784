import './Error.css';

const getErrorMessage = (error: number) => {
  if (error === 404) {
    return {
      title: "Page not found",
      subtitle: "Oops! The page you are looking for is no longer available."
    }
  }

  return {
    title: "Uh oh!",
    subtitle: "Something went wrong"
  }
}

export const Error = ({ error }: { error: number }) => {
  const { title, subtitle } = getErrorMessage(error);

  return (
    <div className="Error">
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
      <a href="/">Home</a>
    </div>
  )
}