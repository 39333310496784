import { About, Error, Header, Home, Resume } from './components';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route
              path="/"
              element={<Home />}
            />
            <Route
              path="about"
              element={<About />}
            />
            <Route
              path="resume"
              element={<Resume />}
            />
            <Route
              path="*"
              element={<Error error={404} />}
            />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
